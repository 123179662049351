import {useDispatch} from "react-redux";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useEffect, useState} from "react";
import {onSignInSuccess} from "../../store/auth/sessionSlice";
import {apiGetUser} from "../../services/AccountServices";

const AuthUser = () => {
    const dispatch = useDispatch()
    const auth = getAuth();

    const [theUser, setUser] = useState()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth,async (user)=>{
            if(user && theUser === undefined)
            {
                dispatch(onSignInSuccess(user.accessToken))
                let response = await apiGetUser().then(
                    r => {
                        console.log(r.data.user)
                        /*							dispatch(setUser(r.data.user || {
                                                        avatar: '',
                                                        userName: 'Anonymous',
                                                        authority: ['USER'],
                                                        email: ''
                                                    }))*/
                        setUser(user ?? false)
                    }
                ).catch(err => {
                    console.warn(err.code)
                })
            }
        });
        return () => {
            unsubscribe();
        };
    }, [])

    useEffect(() => {
        // console.log(theUser)
    },[theUser])

    return theUser;


}

export default AuthUser