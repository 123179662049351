import { createSlice } from '@reduxjs/toolkit'
export const aiSlice = createSlice({
    name: 'ai',
    initialState: {
        categories: []
    },
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload
        },
    },
})

export const { setCategories } = aiSlice.actions

export default aiSlice.reducer