// Import the functions you need from the SDKs you need
import React, {createContext, useContext} from "react";
import AuthUser from "../utils/hooks/useFirebaseAuth";

const { initializeApp } = require("firebase/app");

const firebaseConfig = {
    apiKey: "AIzaSyD9TiODg670u_Yq9TbSoqz2WHeFUei_MR4",
    authDomain: "krylerai.firebaseapp.com",
    projectId: "krylerai",
    storageBucket: "krylerai.appspot.com",
    messagingSenderId: "437747171079",
    appId: "1:437747171079:web:a282654f386d9c11ea0fa2",
    measurementId: "G-DYTMB73QDP"
};


const firebase = initializeApp(firebaseConfig);

// export default firebase;

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
    const authUser = AuthUser()


    return <AuthContext.Provider value={authUser}>{props.children}</AuthContext.Provider>;
}
export const useAuthState = () => {
    const auth = useContext(AuthContext)
    return { ...auth, isAuthenticated: auth != null }
}