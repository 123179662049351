import ApiService from "./ApiService"
const axios = require('axios');

export async function apiGetCategories() {
/*    const options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    }
    return await axios.get(appConfig.apiPrefix + '/ai/categories',options).then(r => {
        return r
    }).catch(err => {
        // console.log(err.response.data.err.name)

        return err.response;
    })*/

        return await ApiService.fetchData({
            url: '/ai/categories',
            method: 'get'
        }).then(r => {
            return r
        }).catch(err => {
            // console.log(err.response.data.err.name)

            return err.response;
        })
}

export async function apiGetCategory(id) {
    return await ApiService.fetchData({
        url: `/ai/categories/${id}`,
        method: 'get'
    }).then(r => {
        return r
    }).catch(err => {
        // console.log(err.response.data.err.name)

        return err.response;
    })
}