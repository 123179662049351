import ApiService from "./ApiService"
import reAuth from '../utils/hooks/useAuth'
import appConfig from "../configs/app.config";
const axios = require('axios');

export async function apiGetAccount (token) {
    const options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    }
    return await axios.get(appConfig.apiPrefix + '/user/auth',options).then(r => {
        return r
    }).catch(err => {
        // console.log(err.response.data.err.name)

        return err.response;
    })
/*
    return await ApiService.fetchData({
        url: '/user/auth',
        method: 'get'
    }).then(r => {
        return r
    }).catch(err => {
        // console.log(err.response.data.err.name)

        return err.response;
    })*/
}

export async function apiUpdateUser(token,payload) {
    const options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    }
    return await axios.put(appConfig.apiPrefix + '/user/auth',payload,options).then(r => {
        return r
    }).catch(err => {
        return err.response;
    })
}

export async function apiGetAccountSettingIntegrationData () {
    return ApiService.fetchData({
        url: '/account/setting/integration',
        method: 'get'
    })
}

export async function apiGetAccountSettingBillingData () {
    return ApiService.fetchData({
        url: '/account/setting/billing',
        method: 'get'
    })
}

export async function apiGetAccountInvoiceData (params) {
    return ApiService.fetchData({
        url: '/account/invoice',
        method: 'get',
        params
    })
}

export async function apiGetAccountLogData (data) {
    return ApiService.fetchData({
        url: '/account/log',
        method: 'post',
        data
    })
}

export async function apiGetAccountFormData () {
    return ApiService.fetchData({
        url: '/account/form',
        method: 'get',
    })
}

export async function apiGetUser () {
    return ApiService.fetchData({
        url: '/user/auth',
        method: 'get',
    })
}