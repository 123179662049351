import {useDispatch, useSelector} from 'react-redux'
import {initialState, setUser} from 'store/auth/userSlice'
import {onSignInSuccess, onSignOutSuccess} from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import {REDIRECT_URL_KEY} from 'constants/app.constant'
import {useNavigate} from 'react-router-dom'
import useQuery from './useQuery'
import {
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	verifyPasswordResetCode,
	confirmPasswordReset,
	signInWithEmailAndPassword,
	signOut, onAuthStateChanged
} from "firebase/auth";
import {apiGetAccount} from "../../services/AccountServices";
import {apiGetCategories} from "../../services/AI.CategoryServices";
import {setCategories} from "../../store/ai";
require('services/FirebaseService')


const axios = require('axios');

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

	const decodeFBErrors = (error,defaultMsg) => {
    	// console.log(error.code)
		switch(error.code) {
			case "auth/wrong-password" || "auth/user-not-found" || "auth/invalid-email":
				return "Please ensure the details entered are correct."
			case "auth/email-already-in-use":
				return "An account already exists with that email address. Please login to continue.";
			case "auth/weak-password":
				return "The password you are trying to use is not strong enough.";
			default:
				return defaultMsg;
		}
	}

    const logIn = async ({ email, password }) => {

		const auth = getAuth();
		const fbUser = await signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in

				const fbUser = userCredential.user;
				const token = fbUser.stsTokenManager.accessToken;

				return {
					status: 200,
					user: fbUser,
					token: token
				}

			})
			.catch((error) => {
				let msg = decodeFBErrors(
					error,
					"Sorry there was a problem signing you in. Please try again"
				);

				return {
					status: 400,
					data: {
						code: error.code,
						msg: msg,
						// error
					}
				}
			});

		//found a matching user in firebase
		if(fbUser.status===200)
		{

			// console.log(fbUser.token);
			const token = fbUser.token;
			const options = {
				headers: {
					ContentType: "application/json",
					Authorization: `Bearer ${token}`
				}
			}
			const user = await axios.get('https://krylerai-api.herokuapp.com/user/auth',options);
			if(user.status===200)
			{
				dispatch(onSignInSuccess(token))
				dispatch(setUser(user.data.user || {
					avatar: '',
					userName: 'Anonymous',
					authority: ['USER'],
					email: ''
				}))
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
			} else {
				//failed lookup in db, logout from firebase
				await logOut(false)
			}
		}
		// console.log(fbUser)
		return fbUser

    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
	}

    const logOut = async (redirect = true) => {
		const auth = getAuth();
		signOut(auth).then(() => {
			handleSignOut()
		}).catch((error) => {
			handleSignOut()
		});
		handleSignOut()
		if(redirect)
		{
			navigate(appConfig.unAuthenticatedEntryPath)
		}
	}

	const signUp = async ({fName,sName,email, password}) => {
		const auth = getAuth();
		const response = await createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				// console.log(userCredential)
				const fbUser = userCredential.user;
				const token = fbUser.stsTokenManager.accessToken;

				return {
					status: 200,
					user: fbUser,
					token: token
				}

			})
			.catch((error) => {
				let msg = decodeFBErrors(
					error,
					"Sorry there was a problem signing you in. Please try again"
				);

				return {
					status: 400,
					data: {
						code: error.code,
						msg: msg,
						// error
					}
				}
			});

		//found a matching user in firebase
		if(response.status===200)
		{

			// console.log(fbUser.token);
			const token = response.token;
			const options = {
				headers: {
					ContentType: "application/json",
					Authorization: `Bearer ${token}`
				}
			}
			// console.log(token)
			const payload = {sName, fName}

			const user = await axios.post('https://krylerai-api.herokuapp.com/user/auth',payload,options);
			if(user.status===200)
			{
				dispatch(onSignInSuccess(token))
				dispatch(setUser(user.data.user || {
					avatar: '',
					userName: 'Anonymous',
					authority: ['USER'],
					email: ''
				}))
				navigate(appConfig.tourPath)
			} else {
				//failed lookup in db, logout from firebase
				await logOut(false)
			}
		}
		// console.log(fbUser)
		return response
	}

	const forgotPassword = (email) => {
		const auth = getAuth();
		return sendPasswordResetEmail(auth, email)
			.then(() => {
				return {
					status: 200,
					data: {

					}
				}
			})
			.catch((error) => {
				let msg = decodeFBErrors(
					error,
					"Sorry there was a problem finding the account"
				);

				return {
					status: 400,
					data: {
						code: error.code,
						msg: msg,
						// error
					}
				}
			});
	}

	const verifyPwdResetCode = async (code) => {
		const auth = getAuth();
		return await verifyPasswordResetCode(auth,code)
			.then((email) => {
				return {
					status: 200,
					email: email
				}
			})
			.catch((error) => {

				return {
					status: 400,
					msg: 'The link you have visited has either expired or is invalid, please try again.'
				}
			});
	}

	const resetPassword = async (code, password) => {
		const auth = getAuth();
		return await confirmPasswordReset(auth, code, password)
			.then((response) => {
				return {
					status: 200
				}
			})
			.catch((error) => {
				return {
					status: 400,
					data: {
						code: error.code,
						msg: 'There was a problem resetting your password, link you have visited has either expired or is invalid, please try again.',
					}
				}
			});
	}

	const reAuth = async (token) => {

		const user = await apiGetAccount(token)
		if(user.status===200)
		{
			const categories = await apiGetCategories()
			// console.log(categories)
			dispatch(setCategories(categories.data.categories))
			return user.data.user;
		} else {
			return false;
		}
		/*if(user.status===200)
		{
			dispatch(setUser(user.data.user || {
				avatar: '',
				userName: 'Anonymous',
				authority: ['USER'],
				email: ''
			}))
		} else {
			//failed lookup in db, logout from firebase
			// await logOut(false)
			console.warn('no user')
		}*/


/*		const auth = getAuth()
		const [user, loading, error] = useAuthState(auth);

		console.log(user,loading,error);*/

/*		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/firebase.User
				console.log(user.accessToken)
				return user.accessToken;
				// ...
			} else {
				// User is signed out
				// ...
				return false;
			}
		});*/
	}
    
    return {
        authenticated: token && signedIn,
        logIn,
        logOut,
		signUp,
		forgotPassword,
		verifyPwdResetCode,
		resetPassword,
		reAuth
    }
}

export default useAuth