import React, {useEffect, useRef, useState} from 'react'
import { Input } from 'components/ui'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import PasswordStrengthBar from 'react-password-strength-bar';



const PasswordInput = (props) => {

	const usePrevious = (value) => {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const {onVisibleChange, ...rest} = props
	const propsPwd = props.field.value;

	const [ pwInputType, setPwInputType ] = useState('password')
	const [pwd, setPwd] = useState(propsPwd);

	const prevPwd = usePrevious(propsPwd);
	useEffect(() => {
		if(prevPwd !== propsPwd) {
			setPwd(propsPwd)
		}
	}, [propsPwd])
	const onPasswordVisibleClick = e => {
		e.preventDefault()
		const nextValue = pwInputType === 'password' ? 'text' : 'password'
		setPwInputType(nextValue)
		onVisibleChange?.(nextValue === 'text')
	}

	return (
		<>
			<Input
				{...rest}
				type={pwInputType}
				suffix={
					<span
						className="cursor-pointer text-xl"
						onClick={e => onPasswordVisibleClick(e)}
					>
					{ pwInputType === 'password' ? <HiOutlineEyeOff /> : <HiOutlineEye />}
				</span>
				}
			/>
			<PasswordStrengthBar password={pwd} minLength={6}/>
		</>
	)
}

export default PasswordInput