import React, {memo, useMemo, lazy, Suspense, useState} from 'react'
import { Loading } from 'components/shared'
import {useDispatch, useSelector} from 'react-redux'
import { 
	LAYOUT_TYPE_CLASSIC, 
	LAYOUT_TYPE_MODERN, 
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {onSignInSuccess, onSignOutSuccess} from "../../store/auth/sessionSlice";
import {initialState, setUser} from "../../store/auth/userSlice";

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}


const Layout = () => {
	const {reAuth} = useAuth();
	// const authenticated = useAuth();
	const isAuthed = useAuth().authenticated;

	const layoutType = useSelector((state) => state.theme.layout.type)
	// const {token}

	// const { authenticated } = useAuth()
	const auth = getAuth();
	const [authenticated, loading, error] = useAuthState(auth);
	const [userFound, setUserFound] = useState();
	const dispatch = useDispatch()

	useDirection()

	useLocale()

	const userHeartBeat = () => {
		// console.log('keep alive started');
		const heartBeatIntervalMinutes = 30;
		const timeout = heartBeatIntervalMinutes*60000;
		// console.log(timeout)
		setInterval( () => {
			auth.currentUser.getIdToken(true).then(user => {
				dispatch(onSignInSuccess(user.accessToken))
			})
		}, timeout)

	}


	const AppLayout = useMemo(() => {
		if(loading)
		{
			return lazy(() => import('components/shared/Loading'))
		}
		if (authenticated) {
			userHeartBeat()
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated, loading])

	if(authenticated)
	{
		dispatch(onSignInSuccess(authenticated.accessToken))
		// userHeartBeat()

		if(userFound === undefined || !isAuthed)
		{
			// console.log('looking up user')
			const user = reAuth(authenticated.accessToken).then(r => {
				if(r)
				{
					dispatch(setUser(r))
					setUserFound(true)
				} else {
					console.warn('Error finding user');
					dispatch(onSignOutSuccess())
					dispatch(setUser(initialState))
				}
			});
		}
	}

	return (
		<Suspense 
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout/>
		</Suspense>
	)
}

export default memo(Layout)